import {
  MenuItem,
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { config } from "../config";
import { Clear as ClearIcon, SwapVertOutlined } from "@mui/icons-material";

const useStyles = makeStyles({
  filterPanel: {
    backgroundColor: "#2c2c2c",
  },
});

const genres = config.validValues.filters.genres; //multiselect
export const allGenresConst = "All";
const contentRatings = config.validValues.filters.contentRatings; //Only one can be selected at a time
const sortOptions = config.validValues.sortOptions;

const FilterPanel = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.filterPanel}>
      <Container maxWidth="md">
        <Stack spacing={2.25} pt={0.25} pb={2}>
          <Stack
            aria-label="genre selection"
            direction="row"
            spacing={1}
            useFlexGap
            flexWrap="wrap"
          >
            {[allGenresConst, ...genres].map((genre) => {
              const isSelected =
                (genre === allGenresConst &&
                  props.selectedGenres.length === 0) ||
                props.selectedGenres.includes(genre);
              return (
                <Button
                  key={genre}
                  onClick={() => props.handleGenreSelectionToggle(genre)}
                  color="monochrome"
                  variant={isSelected ? "contained" : "outlined"}
                  disableElevation
                  size="large"
                  aria-label={`Genre selection - ${genre}`}
                  sx={{
                    textTransform: "none",
                    borderRadius: "1rem",
                    padding: "0 0.75rem",
                  }}
                >
                  {genre === allGenresConst ? "All Genres" : genre}
                </Button>
              );
            })}
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <FormControl sx={{ minWidth: "19ch" }} size="small">
              <InputLabel id="sort-by-select-label">Sort by</InputLabel>
              <Select
                labelId="sort-by-select-label"
                label="Sort by"
                value={props.selectedSortOption}
                onChange={(e) =>
                  props.handleSortOptionSelection(e.target.value)
                }
                inputProps={{
                  "aria-label": "Sort by",
                  MenuProps: { disableScrollLock: true },
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <SwapVertOutlined />
                  </InputAdornment>
                }
              >
                {sortOptions.map((sortOption) => (
                  <MenuItem key={sortOption} value={sortOption}>
                    {sortOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "14ch" }} size="small">
              <InputLabel id="content-rating-select-label">
                Rated for
              </InputLabel>
              <Select
                labelId="content-rating-select-label"
                label="Rated for"
                value={props.selectedContentRating}
                onChange={(e) =>
                  props.handleContentRatingSelectionChange(e.target.value)
                }
                inputProps={{
                  "aria-label": "Content rating menu",
                  MenuProps: { disableScrollLock: true },
                }}
                endAdornment={
                  props.selectedContentRating ? (
                    <IconButton
                      sx={{ mr: "0.5ch" }}
                      onClick={() =>
                        props.handleContentRatingSelectionChange("")
                      }
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  ) : null
                }
              >
                {contentRatings.map((contentRating) => (
                  <MenuItem value={contentRating} key={contentRating}>
                    {contentRating === "Anyone" ? "All" : contentRating}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default FilterPanel;
