import { useState } from "react";
import Header from "./Header";
import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  TextField,
  IconButton,
  Tooltip,
  Container,
} from "@mui/material";
import FilterPanel, { allGenresConst } from "./FilterPanel";
import Dashboard from "./Dashboard";
import { config } from "../config";

const sortOptions = config.validValues.sortOptions;

function LandingPage() {
  const [searchFieldInput, setSearchFieldInput] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [selectedGenres, setSelectedGenres] = useState([]); //lifting state from FilterPanel
  const [selectedContentRating, setSelectedContentRating] = useState(""); //lifting state from FilterPanel
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]); //lifting state from FilterPanel

  const handleSearch = (searchKey = searchFieldInput) => {
    if (searchKey === "") {
      setSearchKey("");
      console.log("No search required. Just display all videos");
    } else {
      setSearchKey(searchKey);
      console.log(`Raise search request with searchText=${searchFieldInput}`);
    }
  };

  //lifting state from FilterPanel
  const handleGenreSelectionToggle = (toggledGenre) => {
    setSelectedGenres((prevGenres) => {
      if (!prevGenres.includes(toggledGenre)) {
        // add genre selection
        return toggledGenre === allGenresConst
          ? [] // empty genre selection === all genres
          : [...prevGenres, toggledGenre];
      }
      // else, remove genre selection
      return prevGenres.filter((genre) => genre !== toggledGenre);
    });
  };

  //lifting state from FilterPanel
  const handleContentRatingSelectionChange = (newSelectedContentRating) => {
    setSelectedContentRating(newSelectedContentRating);
  };

  //lifting state from FilterPanel
  const handleSortOptionSelection = (option) => {
    setSelectedSortOption(option);
  };

  return (
    <div>
      <Header>
        <Tooltip
          title="Press enter or click button to search"
          placement="bottom-start"
          arrow
        >
          <TextField
            size="small"
            fullWidth
            style={{ maxWidth: "40ch" }}
            value={searchFieldInput}
            onChange={(e) => {
              setSearchFieldInput(e.target.value);
              if (e.target.value === "") {
                handleSearch("");
              }
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={(e) => handleSearch()} edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Search"
            name="search"
          />
        </Tooltip>
      </Header>

      <FilterPanel
        selectedGenres={selectedGenres}
        handleGenreSelectionToggle={handleGenreSelectionToggle}
        selectedContentRating={selectedContentRating}
        handleContentRatingSelectionChange={handleContentRatingSelectionChange}
        selectedSortOption={selectedSortOption}
        handleSortOptionSelection={handleSortOptionSelection}
      ></FilterPanel>

      <Container maxWidth="md">
        <Dashboard
          searchKey={searchKey}
          genres={selectedGenres}
          contentRating={selectedContentRating}
          sortBy={selectedSortOption}
        ></Dashboard>
      </Container>
    </div>
  );
}

export default LandingPage;
