import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { Grid, LinearProgress, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import VideoCard from "./VideoCard";
import { config } from "../config";
import { SentimentDissatisfied } from "@mui/icons-material";

const generateQueryString = ({ searchKey, contentRating, genres, sortBy }) => {
  let queryString = "";
  if (searchKey) {
    queryString += `title=${encodeURIComponent(searchKey.trim())}`;
  }
  if (Array.isArray(genres) && genres.length > 0) {
    queryString = queryString.length === 0 ? "" : `${queryString}&`;
    queryString += `genres=${genres.join(",")}`;
  }
  if (contentRating) {
    queryString = queryString.length === 0 ? "" : `${queryString}&`;
    queryString += `contentRating=${encodeURIComponent(contentRating.trim())}`;
  }
  if (sortBy) {
    queryString = queryString.length === 0 ? "" : `${queryString}&`;
    queryString += `sortBy=${sortBy}`;
  }
  return queryString;
};
const fetchVideos = async ({ queryKey }) => {
  const [searchKey, contentRating, genres, sortBy] = queryKey;
  const queryString = generateQueryString({
    searchKey,
    contentRating,
    genres,
    sortBy,
  });
  const response = await axios.get(
    `${config.backend_endpoint}/videos?${queryString}`
  );
  return response.data.videos;
};

const useStyles = makeStyles({
  loadingDashboard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "30vh",
    color: "grey",
  },
});

const Dashboard = (props) => {
  const classes = useStyles();

  const { data: videoList = [], isLoading } = useQuery({
    queryKey: [
      props.searchKey,
      props.contentRating,
      props.genres,
      props.sortBy,
    ],
    refetchOnWindowFocus: false,
    queryFn: fetchVideos,
  });

  return (
    <div>
      {isLoading ? (
        <Box className={classes.loadingDashboard}>
          <Box sx={{ width: "25%" }}>
            <LinearProgress />
          </Box>
          <p>Loading Dashboard..</p>
        </Box>
      ) : (
        <>
          {videoList.length === 0 ? (
            <Box className={classes.loadingDashboard}>
              <SentimentDissatisfied fontSize="large" />
              <p>No videos found</p>
            </Box>
          ) : (
            <Grid container columnSpacing={2} rowSpacing={3} sx={{ pt: 2 }}>
              {videoList.map((videoObj) => {
                return (
                  <Grid item xs={12} sm={6} md={3} key={videoObj._id}>
                    <Link
                      to={`/video/${videoObj._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <VideoCard
                        value={videoObj._id}
                        imageLink={videoObj.previewImage}
                        title={videoObj.title}
                        releaseDate={videoObj.releaseDate}
                      ></VideoCard>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  searchKey: PropTypes.string,
  contentRating: PropTypes.string,
  genres: PropTypes.arrayOf(PropTypes.string),
  sortBy: PropTypes.string,
};

export default Dashboard;
