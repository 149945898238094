import PropTypes from "prop-types";
import { ImageListItemBar, ImageListItem } from "@mui/material";

const VideoCard = (props) => {
  return (
    <ImageListItem spacing={0} sx={{ width: "100%" }}>
      <img src={props.imageLink} alt={props.title} loading="lazy" />
      <ImageListItemBar
        title={props.title}
        subtitle={props.releaseDate}
        position="below"
      />
    </ImageListItem>
  );
};

VideoCard.propTypes = {
  imageLink: PropTypes.string,
  title: PropTypes.string,
  releaseDate: PropTypes.string,
};

export default VideoCard;
