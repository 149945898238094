import PropTypes from "prop-types";
import { Box, Container, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import UploadButtonWithDialogForm from "./UploadButtonWithDialogForm";

const useStyles = makeStyles({
  header: {
    backgroundColor: "#2c2c2c",
  },
  btn: {
    textTransform: "none",
  },
  logoImage: {
    height: "1.5rem",
  },
});

const Header = (props) => {
  const classes = useStyles();
  const children = props.children;
  return (
    <Box className={classes.header}>
      <Container maxWidth="md">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pt="0.75rem"
          pb="0.5rem"
        >
          <Box sx={{ mr: "1.25ch" }}>
            <Link to="/">
              <img
                src="/Xflix-logo-ss.png"
                alt="xflix-logo"
                className={classes.logoImage}
              ></img>
            </Link>
          </Box>

          {children}

          <UploadButtonWithDialogForm />
        </Stack>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
