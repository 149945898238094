import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LandingPage from "./components/LandingPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import VideoPlayPage from "./components/VideoPlayPage";
import { CssBaseline } from "@mui/material";

export const config = {
  backend_endpoint: `https://xflix-chris-node-be.onrender.com/v1`,
};
const queryClient = new QueryClient();

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    monochrome: {
      main: "#ffffff",
      light: "#ffffff",
      dark: "#ffffff",
      contrastText: "#000000",
    },
  },
  components: {
    MuiImageListItemBar: {
      styleOverrides: {
        titleWrap: {
          paddingTop: 3,
          paddingBottom: 0,
          color: "white",
        },
        title: {
          whiteSpace: "normal",
          fontWeight: "700",
          lineHeight: 1.4,
        },
        subtitle: {
          paddingTop: 8,
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/video/:videoId" element={<VideoPlayPage />} />

          {/* Handling no match route */}
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem", color: "grey" }}>
                <h1>There's nothing here!</h1>
              </main>
            }
          />
        </Routes>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
